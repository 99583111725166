import React from 'react';

import Map from '../components/Maps/Map';
import Master from '../components/Master';
// import CamaImage from '../images/cama.jpeg';
import '../style.css';

const Page = ({ pageContext }) => {
    return (
        <Master>
            <div className="px-5">
                <h1>{pageContext.Title}</h1>
                <p>{pageContext.Description}</p>
            </div>
            <div className="divide-y divide-gray-300 text-sm">
                <div className="py-2">
                    <div className="relative">
                        <img
                            // src={CamaImage}
                            alt="A dog smiling in a party hat"
                        />
                        <div className="absolute left-0 top-0 bg-slate-700/70 mt-1 ml-1 px-2 py-1 rounded-sm text-white text-xs ">
                            <span>New</span>
                        </div>
                    </div>
                    <div className="px-2">
                        <div>
                            <div className=" text-slate-800 text-2xl">
                                € 123.44
                            </div>
                        </div>
                        <div className="inline-flex divide-x divide-gray-400 text-md">
                            <div className="pr-2">2 bds</div>
                            <div className="px-2">3 ba</div>
                            <div className="px-2">123 m.</div>
                            <div className="px-2">Apartment</div>
                        </div>
                        <div className="pt-2">
                            Ord Cratloe Road, Clonconane, Co. Limerick{' '}
                            <span className="whitespace-nowrap">V94 RW11</span>
                        </div>
                        <div className="pt-2 border-b-2 border-gray-400 mb-2 pb-2">
                            Apartment
                        </div>
                        <div className="h-60">
                            <Map lat={-34.397} lng={150.644} zoom={18}></Map>
                        </div>
                    </div>
                </div>
            </div>
        </Master>
    );
};

export default Page;
